.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
}

.logo img {
  width: 28px;
}

.buttons {
  display: flex;
  gap: 28px;
}

.buttons a {
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s;
}

.start {
  background-color: #4f3dfe;
}

.start:hover {
  background-color: #3f2de0;
}

.schedule {
  border: 1px solid #4f3dfe;
}

.schedule:hover {
  background-color: #4f3dfe;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
}

.hero-text {
  font-size: 60px;
  font-weight: 800;
  text-align: center;
}

.hero-text span {
  color: #4f3dfe;
}

.hero img {
  width: 85%;
}

.countdown {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.values {
  display: flex;
  gap: 16px;
  align-items: center;
}

.divider {
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
}

.countdown-item {
  text-align: center;
  width: fit-content;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.value {
  background-color: #4f3dfe;
  width: fit-content;
  padding: 16px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
}

.buttons-hero {
  display: none;
}

@media screen and (max-width: 1220px) {
  .app {
    margin: 0 48px;
  }
  .hero-text {
    font-size: 48px;
  }

  .hero {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 15%;
    transform: none;
  }

  .hero img {
    width: 60%;
  }
}

@media screen and (max-width: 1000px) {
  .hero {
    flex-direction: column;
  }

  .left {
    margin-bottom: 24px;
  }

  .hero img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .hero {
    margin-top: 10%;
  }

  .hero-text {
    font-size: 36px;
  }
  .value {
    padding: 14px;
    font-size: 20px;
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    display: none;
  }

  .buttons-hero {
    display: block;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }

  .buttons-hero a {
    font-weight: 600;
    font-size: 16px;
    padding: 16px 0;
  }
}

@media screen and (max-width: 500px) {
  .hero {
    margin-top: 10%;
  }

  .hero-text {
    font-size: 28px;
  }
}
