body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0a1f;
  color: white;
  max-width: 1200px;
  margin: 40px auto;
}

a {
  text-decoration: none;
  color: white;
}
